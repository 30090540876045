<template lang="pug">
#oferta.offer
  h2 Escolha o seu plano
  .subtitle Junte-se a milhares de assinantes que já estão amando receber a Magenta!

  .offer-plans

    NuxtLink#plano-anual.offer-plan(
      :to="offerPath('annually')",
      style="background-color: #ef368b"
    )
      .offer-plan--header
        span.offer-plan--title Anual

      .offer-plan--pricing
        span.bold.large R$149
        span.bold ,90/mês
        br
        span + frete

      .offer-plan--gift(v-if="annuallyGiftPromotion != null")
        .icon
          fa(:icon="['fas', 'gift']")

        .description
          | E ganhe
          br
          span.bold {{ annuallyGiftPromotion?.giftDescription }}

      .offer-plan--description(v-else)
        | Cobrança anual parcelada

      .offer-plan--cta Eu quero

    NuxtLink#plano-semestral.offer-plan(
      :to="offerPath('biannually')",
      style="background-color: #65c9e0"
    )
      .offer-plan--header
        span.offer-plan--title Semestral

      .offer-plan--pricing
        span.bold.large R$159
        span.bold ,90/mês
        br
        span + frete

      .offer-plan--gift(v-if="biannuallyGiftPromotion != null")
        .icon
          fa(:icon="['fas', 'gift']")

        .description
          | E ganhe
          br
          span.bold {{ biannuallyGiftPromotion?.giftDescription }}

      .offer-plan--description(v-else)
        | Cobrança semestral parcelada

      .offer-plan--cta Eu quero


    NuxtLink#plano-mensal.offer-plan(
      :to="offerPath('monthly')",
      style="background-color: #7b61ff"
    )
      .offer-plan--header
        span.offer-plan--title Mensal

      .offer-plan--pricing
        span.bold.large R$169
        span.bold ,90/mês
        br
        span + frete

      .offer-plan--gift(v-if="monthlyGiftPromotion != null")
        .icon
          fa(:icon="['fas', 'gift']")

        .description
          | E ganhe
          br
          span.bold {{ monthlyGiftPromotion?.giftDescription }}

      .offer-plan--description(v-else)
        | Cancele quando quiser, sem multa

      .offer-plan--cta Eu quero

  span.notice Renovação automática em todos os planos

  ClientOnly
    SimpleSubscriptionGiftPopup
</template>
<script setup lang="ts">
import type { PropType } from "vue"
import type { SubscriptionGiftAvailabilityResponse } from "~/services/subscription/promotion/promotionService"

import { planAliasToId } from "~/services/subscription/subscriptionService"
import { useAuth } from "~/store/auth"

import SimpleSubscriptionGiftPopup from "~/pages/Home/Promotions/SubscriptionGift/SimpleSubscriptionGiftPopup.vue"

const props = defineProps({
  subscriptionGiftPromotions: {
    type: Object as PropType<SubscriptionGiftAvailabilityResponse[]>,
    required: true,
    default: []
  }
})

const auth = useAuth()
const experiments = useExperiments()
const route = useRoute()

const offerPath = (plan: string) => {
  const promotion = subscriptionGiftPromotionForPlan(plan)?.ruleId
  const subscriptionGiftId = promotion ?? undefined
  const coupon = route.query.coupon ?? undefined

  const query = {
    plan,
    subscriptionGiftId,
    coupon
  }

  if (auth.isAuthenticated) {
    return { name: 'subscribe', query }
  } else {
    return { name: 'subscription-register', query }
  }
}

const subscriptionGiftPromotionForPlan = (plan: string) => {
  const planId = planAliasToId(plan);
  const promotion = props.subscriptionGiftPromotions.find((promotion) =>
    promotion.planIds.indexOf(planId) >= 0
  );

  return promotion ?? null;
};

const monthlyGiftPromotion = computed(() => subscriptionGiftPromotionForPlan("monthly"));
const biannuallyGiftPromotion = computed(() => subscriptionGiftPromotionForPlan("biannually"));
const annuallyGiftPromotion = computed(() => subscriptionGiftPromotionForPlan("annually"));
</script>

<style lang="sass" scoped>
@import "~/assets/styles/mixins"

.offer
  display: flex
  width: 100%
  max-width: 1232px
  margin: 0 auto
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 56px 16px

  h2
    position: relative
    font-size: 32px
    margin: 0 0 24px

  > .subtitle
    text-align: center
    font-size: 16px
    margin: 0 0 24px

  .offer-plans
    display: flex
    width: 100%
    max-height: 720px
    flex-direction: row
    justify-content: center
    flex-wrap: wrap
    align-content: center

    &.christmas
      .offer-plan

        &:first-child
          background-color: #D8012B !important

        &:nth-child(2)
          background-color: #B60023 !important

        &:nth-child(3)
          background-color: #8F001C !important

    .offer-plan
      position: relative
      display: flex
      flex-direction: column
      align-items: stretch
      gap: 12px
      flex-grow: 1
      min-width: 302px
      max-width: 380px
      box-shadow: 0px 25px 65px rgba(0, 0, 0, 0.2)
      border-radius: 16px
      margin: 8px
      padding: 16px
      color: #fff
      text-align: center
      justify-content: space-between
      align-items: center
      font-family: 'Spartan', sans-serif
      background-color: #000

      .offer-plan--header
        position: relative

        span.offer-plan--title
          font-size: 32px
          font-weight: 800
          margin: 0
          text-transform: uppercase
          flex-grow: 1

      .offer-plan--pricing
        font-size: 18px
        line-height: 26px

        span.small
          font-size: 14px

        span.large
          font-size: 28px
          line-height: 38px

      span.bold
        font-weight: 800

      span.strikethrough
        text-decoration: line-through

        br.installments-line-break
          display: none

      .offer-plan--gift
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        font-size: 12px
        max-width: 240px
        background-color: rgba(0, 0, 0, 0.2)
        padding: 8px 12px
        border-radius: 4px
        gap: 4px
        white-space: pre-line

        .icon
          display: flex
          justify-content: center
          align-items: center
          height: 32px
          width: 32px
          font-size: 16px
          flex-shrink: 0

      .offer-plan--cta
        position: relative
        display: inline-block
        width: 148px
        height: 42px
        align-self: center
        font-size: 22px
        line-height: 46px
        font-weight: 800
        background-color: #fff
        border-radius: 21px
        color: #000

        &:hover
          transform: translateY(-2px)
          box-shadow: 0 2px 2px rgba(60, 60, 60, 0.4)

      &.biannually
        padding-bottom: 18px

        .offer-plan--header, .offer-plan--pricing
          text-align: left

        .offer-plan--cta
          position: absolute
          bottom: 16px

  span.notice
    margin-top: 32px
    color: rgb(90, 90, 90)
    text-align: center

@include upperBreakpoint(mobileonly)
  .offer
    .offer-plans
      max-height: 100%

      .offer-plan
        .offer-plan--header
          span.offer-plan--title
            font-size: 28px

        .offer-plan--pricing
          font-size: 18px

          span.small
            font-size: 14px

          span.large
            font-size: 28px

@include upperBreakpoint(extrasmall)
  .offer
    .offer-plans
      .offer-plan
        .offer-plan--header
          span.offer-plan--title
            font-size: 18px
</style>
